<script>
import get from 'lodash/get';
import { mapGetters, mapState } from 'vuex';
import { DELAY, navigationErrorHandler } from '@emobg/web-utils';
import { MuiAlgoliaList } from '@emobg/motion-ui';

import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils/algolia';
import { contentCells, facets } from './config';

import PromotionForm from './components/PromotionForm';
import DeletePromotionComponent from './components/DeletePromotionComponent';
import { PROMOTION_MODAL_QUERIES } from './constants/modal.constant';

export default {
  name: 'PromotionsListView',
  components: {
    DeletePromotionComponent,
    MuiAlgoliaList,
    PageView,
    PromotionForm,
  },
  data() {
    return {
      isLoading: false,
      isModalVisible: false,
      isDeleteModalVisible: false,
      promotionUuid: null,
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      operatorTimezone: state => state.operators.active.timezone,
    }),
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
    mainConfig() {
      return contentCells({ operatorTimezone: this.operatorTimezone });
    },
  },
  watch: {
    isModalVisible(newIsModalVisible) {
      const newModalQuery = newIsModalVisible ? { modal: PROMOTION_MODAL_QUERIES.add } : {};
      this.$router.push({ query: newModalQuery }).catch(navigationErrorHandler);
    },
  },
  created() {
    // TODO: remove if Hotjar can't distinguish URLS by query CF-574
    this.isModalVisible = get(this, '$route.query.modal', '') === PROMOTION_MODAL_QUERIES.add;
    // TODO: remove if Hotjar can't distinguish URLS by query CF-574

    this.PROMOTIONS_INDEX = ALGOLIA_INDEXES.promotions;
    this.facets = facets;
    this.itemActions = [
      {
        label: this.$t('Common.Actions.edit_element', { element: 'promotion' }),
        method: ({ uuid }) => {
          this.promotionUuid = uuid;
          this.isModalVisible = true;
        },
      },
      {
        label: 'Delete promotion',
        class: 'emobg-color-danger',
        method: ({ uuid }) => {
          this.promotionUuid = uuid;
          this.isDeleteModalVisible = true;
        },
      },
    ];
  },
  methods: {
    closeModals() {
      this.isModalVisible = false;
      this.isDeleteModalVisible = false;
      this.promotionUuid = null;
    },
    onFormSuccess() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.promotions, DELAY.extraLong, () => { this.isLoading = false; });
    },
  },
};
</script>

<template>
  <PageView
    class="PromotionsListView"
    data-test-id="promotions_list-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Credit and Promotions
      </h1>
      <ui-button
        data-test-id="create-button"
        @clickbutton="isModalVisible = true"
      >
        Add promotion
      </ui-button>
    </div>

    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="promotions"
        :facets="facets"
        :filters="getOperatorFilter({ attribute: 'cs_operator_fk' })"
        :index="PROMOTIONS_INDEX"
        :table-config="mainConfig"
        :item-actions="itemActions"
        data-test-id="list"
      />
    </div>

    <PromotionForm
      v-if="isModalVisible"
      :promotion-uuid="promotionUuid"
      :callback="onFormSuccess"
      data-test-id="form"
      @closeModal="closeModals"
    />

    <DeletePromotionComponent
      v-if="isDeleteModalVisible"
      :promotion-uuid="promotionUuid"
      :callback="onFormSuccess"
      data-test-id="delete-form"
      @closeModal="closeModals"
    />
  </PageView>
</template>
