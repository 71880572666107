var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeletePromotion",
        attrs: {
          header: null,
          size: _vm.SIZES.small,
          "data-test-id": "delete_promotion-modal",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c("DeleteModalBodyComponent", {
            attrs: {
              text: "This operation can not be undone",
              title: "Are you sure you want to delete this promotion?",
              "data-test-id": "body",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("closeModal")
              },
            },
          }),
          _c("DeleteButton", {
            attrs: {
              loading: _vm.deletePromotionStatus.LOADING,
              "data-test-id": "delete-button",
            },
            on: { click: _vm.removePromotion },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }