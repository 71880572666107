import toLower from 'lodash/toLower';
import {
  DATE_FORMAT,
  reformatDateTime,
  sentenceCase,
} from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells({ operatorTimezone }) {
  return [
    {
      title: 'Internal name',
      attributeName: 'internal_name',
      displayPriority: 0,
    },
    {
      title: 'Name',
      attributeName: 'name',
      displayPriority: 1,
    },
    {
      title: 'Code',
      attributeName: 'code',
      displayPriority: 1,
    },
    {
      attributeName: 'start_date',
      title: 'Start date',
      displayPriority: 1,
      columnRatio: 1,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.dob, operatorTimezone),
    },
    {
      attributeName: 'end_date',
      title: 'End date',
      displayPriority: 1,
      columnRatio: 1,
      transformValue: value => reformatDateTime(value, DATE_FORMAT.dob, operatorTimezone),
    },
    {
      title: 'Duration',
      attributeName: 'duration',
      displayPriority: 0,
      transformValue: value => `${value} days`,
    },
    {
      title: 'Amount',
      attributeName: 'amount_formatted',
      displayPriority: 0,
    },
    {
      attributeName: 'active',
      title: 'Status',
      displayPriority: 1,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ active }) => ({
        text: sentenceCase(active),
        color: toLower(active) === 'active' ? COLORS.success : GRAYSCALE.ground,
      }),
    },
  ];
}

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Active',
      attributeName: 'active',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'rangeslider',
    props: {
      title: 'Duration',
      attributeName: 'duration',
      step: 10,
      min: 0,
    },
  },
];
