var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "PromotionForm",
        attrs: {
          title: _vm.modalTitle,
          header: { isClosable: true },
          "data-test-id": "promotion_form-modal",
        },
      },
      _vm.$listeners
    ),
    [
      _c(
        "template",
        { slot: "alerts" },
        [
          _c("StoreNotificationComponent", {
            attrs: {
              "store-domain": _vm.DOMAINS_MODEL.promotions.promotions,
              scope: _vm.PROMOTION_SCOPES.newPromotion,
              "is-editing": !!_vm.promotionUuid,
              element: "promotion",
              "data-test-id": "notification",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          !_vm.isInitialized
            ? _c("ui-loader", {
                attrs: {
                  label: "Loading promotion...",
                  "data-test-id": "loader",
                },
              })
            : _c(
                "MuiValidationWrapper",
                {
                  attrs: { "data-test-id": "validation" },
                  on: { areAllValid: (valid) => (_vm.isFormValid = valid) },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "pr-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "d-block emobg-font-weight-semibold mb-1",
                                  },
                                  [_vm._v(" Internal name* ")]
                                ),
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n                  isRequired: true,\n                }",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "Enter internal name",
                                    name: "internalName",
                                    "data-test-id": "internal_name-input",
                                  },
                                  model: {
                                    value: _vm.inputs.internalName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "internalName", $$v)
                                    },
                                    expression: "inputs.internalName",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("ui-datetimepicker", {
                              staticClass: "mb-4 d-block",
                              attrs: {
                                size: _vm.SIZES.small,
                                disabled: !!_vm.isStartDateInPast,
                                label: "Start date",
                                name: "start",
                                "data-test-id": "start_date-input",
                                skiptime: "",
                              },
                              domProps: {
                                date: _vm.inputs.startDate,
                                range: _vm.validStartDateRange,
                              },
                              on: {
                                datechanged: ({ detail }) => {
                                  _vm.inputs.startDate = detail
                                  _vm.validateStartDate(detail)
                                },
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "d-block emobg-font-weight-semibold mb-1",
                                  },
                                  [_vm._v(" User code* ")]
                                ),
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                      },
                                      expression:
                                        "{\n                  isRequired: true,\n                }",
                                    },
                                  ],
                                  attrs: {
                                    disabled: !!_vm.promotionUuid,
                                    placeholder: "Enter the user code",
                                    name: "code",
                                    "data-test-id": "code-input",
                                  },
                                  model: {
                                    value: _vm.inputs.code,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "code", $$v)
                                    },
                                    expression: "inputs.code",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("ui-select", {
                              staticClass: "w-100 mb-4 d-block",
                              attrs: {
                                value: _vm.inputs.type,
                                disabled: !!_vm.promotionUuid,
                                name: "type",
                                placeholder: "Select the type",
                                label: "Type*",
                                "data-test-id": "type-select",
                              },
                              domProps: {
                                options: _vm.map(
                                  _vm.PROMOTION_TYPES,
                                  (type) => ({
                                    label: _vm.sentenceCase(type),
                                    value: type,
                                  })
                                ),
                              },
                              on: {
                                selectoption: ({ detail }) =>
                                  (_vm.inputs.type = detail),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c(
                                  "label",
                                  {
                                    staticClass:
                                      "d-block emobg-font-weight-semibold mb-1",
                                  },
                                  [
                                    _vm._v(
                                      " Amount in " +
                                        _vm._s(_vm.currencySymbol) +
                                        "* "
                                    ),
                                  ]
                                ),
                                _c("MuiInputText", {
                                  directives: [
                                    {
                                      name: "validate",
                                      rawName: "v-validate",
                                      value: {
                                        isRequired: true,
                                        isPattern:
                                          _vm.PATTERN_INPUT_VALIDATIONS
                                            .decimalNumber,
                                      },
                                      expression:
                                        "{\n                  isRequired: true,\n                  isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,\n                }",
                                    },
                                  ],
                                  attrs: {
                                    placeholder: "Enter the amount",
                                    name: "amount",
                                    "data-test-id": "amount-input",
                                  },
                                  model: {
                                    value: _vm.inputs.amount,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.inputs, "amount", $$v)
                                    },
                                    expression: "inputs.amount",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "pl-2" },
                        [
                          _c(
                            "label",
                            {
                              staticClass:
                                "d-block emobg-font-weight-semibold mb-1",
                            },
                            [_vm._v(" Active ")]
                          ),
                          _c("ui-toggle", {
                            staticClass: "mb-4 py-1",
                            attrs: {
                              value: _vm.inputs.active,
                              text: _vm.inputs.active ? "Active" : "Inactive",
                              disabled: !!_vm.isEndDateInPast,
                              "data-test-id": "active-toggle",
                            },
                            on: {
                              changevalue: ({ detail }) =>
                                (_vm.inputs.active = detail),
                            },
                          }),
                          _c("ui-datetimepicker", {
                            staticClass: "my-4 d-block",
                            attrs: {
                              size: _vm.SIZES.small,
                              disabled: !_vm.inputs.startDate,
                              label: "End date",
                              name: "end",
                              "data-test-id": "end_date_input",
                              skiptime: "",
                            },
                            domProps: {
                              date: _vm.inputs.endDate,
                              range: _vm.validEndDateRange,
                            },
                            on: {
                              datechanged: ({ detail }) => {
                                _vm.inputs.endDate = detail
                                _vm.validateEndDate(detail)
                              },
                            },
                          }),
                          !_vm.promotionUuid
                            ? _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "d-block emobg-font-weight-semibold mb-1",
                                    },
                                    [_vm._v(" External name* ")]
                                  ),
                                  _c("MuiInputText", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: {
                                          isRequired: true,
                                        },
                                        expression:
                                          "{\n                  isRequired: true,\n                }",
                                      },
                                    ],
                                    attrs: {
                                      placeholder: "Enter the name",
                                      name: "name",
                                      "data-test-id": "name-input",
                                    },
                                    model: {
                                      value: _vm.inputs.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputs, "name", $$v)
                                      },
                                      expression: "inputs.name",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "mb-4" },
                            [
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "d-block emobg-font-weight-semibold mb-1",
                                },
                                [_vm._v(" Duration (days)* ")]
                              ),
                              _c("MuiInputText", {
                                directives: [
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: {
                                      isRequired: true,
                                      isPattern:
                                        _vm.PATTERN_INPUT_VALIDATIONS
                                          .wholeNumber,
                                    },
                                    expression:
                                      "{\n                  isRequired: true,\n                  isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,\n                }",
                                  },
                                ],
                                attrs: {
                                  placeholder: "Enter the duration in days",
                                  name: "duration",
                                  "data-test-id": "duration-input",
                                },
                                model: {
                                  value: _vm.inputs.duration,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputs, "duration", $$v)
                                  },
                                  expression: "inputs.duration",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
        ],
        1
      ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          {
            staticClass:
              "d-flex justify-content-center justify-content-sm-end align-items-center",
          },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c("CancelButton", {
                  attrs: { "data-test-id": "close_modal-button" },
                  on: { click: () => _vm.$emit("closeModal") },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "ui-button",
                  {
                    staticClass: "wmin-initial",
                    attrs: {
                      disabled: !_vm.isFormValid || _vm.hasSameValues,
                      loading: _vm.newPromotionStatus.LOADING,
                      "data-test-id": "request-button",
                    },
                    on: { clickbutton: _vm.request },
                  },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.promotionUuid ? "Save" : "Create") + " "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }