<script>
import { mapActions, mapState } from 'vuex';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';
import promotions from '../../store/PromotionsModuleMap';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    promotionUuid: {
      type: String,
      required: true,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(promotions.promotions, {
      deletePromotionStatus: state => state.deletePromotion.STATUS,
    }),
  },
  methods: {
    async removePromotion() {
      await this.deletePromotion(this.promotionUuid);
      this.$emit('closeModal');
      if (!this.deletePromotionStatus.ERROR) {
        this.$notify({
          message: 'Promotion deleted successfully!',
          textAction: '',
        });
        this.callback();
      }
    },
    ...mapActions(promotions.promotions, [
      'deletePromotion',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeletePromotion"
    data-test-id="delete_promotion-modal"
    v-on="$listeners"
  >
    <template slot="body">
      <DeleteModalBodyComponent
        text="This operation can not be undone"
        title="Are you sure you want to delete this promotion?"
        data-test-id="body"
      />
    </template>
    <template slot="footer">
      <CancelButton
        data-test-id="cancel-button"
        @click="$emit('closeModal')"
      />
      <DeleteButton
        :loading="deletePromotionStatus.LOADING"
        data-test-id="delete-button"
        @click="removePromotion"
      />
    </template>
  </GenericModalComponent>
</template>
