<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import toString from 'lodash/toString';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiInputText,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import { DATE_FORMAT, sentenceCase } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PATTERN_INPUT_VALIDATIONS } from '@/utils';
import {
  CancelButton,
  GenericModalComponent,
  StoreNotificationComponent,
} from '@/components';
import { MAX_END_DATE_PROMOTION, PROMOTION_TYPES } from './const';
import { PROMOTION_SCOPES } from '../../store/PromotionsModule';

export default {
  name: 'PromotionForm',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    GenericModalComponent,
    MuiInputText,
    MuiValidationWrapper,
    StoreNotificationComponent,
  },
  props: {
    promotionUuid: {
      type: String,
      default: null,
    },
    callback: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isFormValid: false,
      isInitialized: false,
      originalInputs: {},
      inputs: {
        active: false,
        amount: null,
        code: null,
        duration: null,
        startDate: moment(),
        endDate: moment(MAX_END_DATE_PROMOTION),
        internalName: null,
        name: null,
        type: PROMOTION_TYPES.campaign,
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.app.userAccount, {
      activeOperatorUuid: state => state.operators.active.uuid,
      currencySymbol: state => state.operators.configuration.currencySymbol,
    }),
    ...mapState(DOMAINS_MODEL.promotions.promotions, {
      promotion: state => state.promotion.data,
      newPromotionStatus: state => state.newPromotion.STATUS,
      newPromotion: state => state.newPromotion.data,
    }),
    modalTitle() {
      return this.promotionUuid ? 'Edit promotion' : 'Add promotion';
    },
    hasSameValues() {
      return isEqual(this.inputsForRequest(this.inputs), this.inputsForRequest(this.originalInputs));
    },
    validEndDateRange() {
      const minStart = moment(this.inputs.startDate).add(1, 'day');

      return {
        start: minStart,
        end: moment().add(50, 'years'),
      };
    },
    isEndDateInPast() {
      return this.promotionUuid ? moment(this.promotion.endDate).isBefore(moment()) : false;
    },
    isStartDateInPast() {
      return this.promotionUuid ? moment(this.promotion.startDate).isBefore(moment()) : false;
    },
  },
  watch: {
    'inputs.startDate': {
      handler(newDate) {
        const startDate = moment(newDate);
        const endDate = moment(this.inputs.endDate);
        if (this.inputs.endDate && endDate.isSameOrBefore(startDate)) {
          this.inputs.endDate = startDate.add(1, 'day');
        }
      },
    },
  },
  async created() {
    this.PROMOTION_TYPES = PROMOTION_TYPES;
    this.DATE_FORMAT = DATE_FORMAT;
    this.PROMOTION_SCOPES = PROMOTION_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.PATTERN_INPUT_VALIDATIONS = PATTERN_INPUT_VALIDATIONS;

    this.resetState([PROMOTION_SCOPES.promotion, PROMOTION_SCOPES.newPromotion]);
    if (this.promotionUuid) {
      await this.getPromotion(this.promotionUuid);
      this.inputs.active = get(this, 'promotion.active', false);
      this.inputs.amount = toString(get(this, 'promotion.amount') ? get(this, 'promotion.amount', 0) / 100 : null);
      this.inputs.code = get(this, 'promotion.code');
      this.inputs.duration = get(this, 'promotion.duration');
      this.inputs.internalName = get(this, 'promotion.internalName');
      this.inputs.type = get(this, 'promotion.type');
      this.inputs.startDate = moment(get(this, 'promotion.startDate', moment()));
      this.inputs.endDate = moment(get(this, 'promotion.endDate', moment(MAX_END_DATE_PROMOTION)));
      this.originalInputs = cloneDeep(this.inputs);
    }

    this.validStartDateRange = {
      start: moment(this.inputs.startDate),
      end: moment().add(10, 'years'),
    };

    this.isInitialized = true;
  },
  methods: {
    map,
    sentenceCase,
    async request() {
      this.clearErrors();
      const request = this.promotionUuid ? this.putPromotion : this.postPromotion;
      const params = this.promotionUuid
        ? { promotionUuid: this.promotionUuid, data: this.inputsForRequest(this.inputs) }
        : this.inputsForRequest(this.inputs);
      await request(params);

      if (!this.newPromotionStatus.ERROR) {
        const { id } = this.newPromotion;
        const message = this.promotionUuid
          ? 'Promotion edited successfully!'
          : `Promotion added successfully with <span class="emobg-font-weight-bold">Id ${id}.</span>`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.callback(id);
        this.resetState([PROMOTION_SCOPES.promotion, PROMOTION_SCOPES.newPromotion]);
      }
    },
    validateStartDate(newDate) {
      if (!newDate.isValid()) {
        this.inputs.startDate = moment().startOf('day');
      }
    },
    validateEndDate(newDate) {
      if (newDate && newDate.isValid()) {
        this.$nextTick(() => {
          if (newDate.isBefore(this.validEndDateRange.start)) {
            this.inputs.endDate = moment(this.validEndDateRange.start).add(1, 'day');
          }
        });
      }
    },
    inputsForRequest(data = {}) {
      const inputs = cloneDeep(data);
      inputs.startDate = inputs.startDate ? inputs.startDate.format(DATE_FORMAT.date) : null;
      inputs.endDate = inputs.endDate ? inputs.endDate.format(DATE_FORMAT.date) : null;
      inputs.csOperatorUuid = this.activeOperatorUuid;
      return this.promotionUuid ? omit(inputs, 'name') : inputs;
    },
    ...mapActions(DOMAINS_MODEL.promotions.promotions, [
      'getPromotion',
      'postPromotion',
      'putPromotion',
    ]),
    ...mapMutations(DOMAINS_MODEL.promotions.promotions, [
      'resetState',
      'clearErrors',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="PromotionForm"
    data-test-id="promotion_form-modal"
    v-on="$listeners"
  >
    <template slot="alerts">
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.promotions.promotions"
        :scope="PROMOTION_SCOPES.newPromotion"
        :is-editing="!!promotionUuid"
        element="promotion"
        data-test-id="notification"
      />
    </template>
    <template slot="body">
      <ui-loader
        v-if="!isInitialized"
        label="Loading promotion..."
        data-test-id="loader"
      />
      <MuiValidationWrapper
        v-else
        data-test-id="validation"
        @areAllValid="valid => isFormValid = valid"
      >
        <div class="row">
          <div class="col-6 emobg-border-right-1 emobg-border-color-ink-lighter emobg-border-radius-none">
            <div class="pr-2">
              <div class="mb-4">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Internal name*
                </label>
                <MuiInputText
                  v-model="inputs.internalName"
                  v-validate="{
                    isRequired: true,
                  }"
                  placeholder="Enter internal name"
                  name="internalName"
                  data-test-id="internal_name-input"
                />
              </div>

              <ui-datetimepicker
                :size="SIZES.small"
                :date.prop="inputs.startDate"
                :range.prop="validStartDateRange"
                :disabled="!!isStartDateInPast"
                label="Start date"
                name="start"
                data-test-id="start_date-input"
                class="mb-4 d-block"
                skiptime
                @datechanged="({ detail }) => {
                  inputs.startDate = detail;
                  validateStartDate(detail);
                }"
              />

              <div class="mb-4">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  User code*
                </label>
                <MuiInputText
                  v-model="inputs.code"
                  v-validate="{
                    isRequired: true,
                  }"
                  :disabled="!!promotionUuid"
                  placeholder="Enter the user code"
                  name="code"
                  data-test-id="code-input"
                />
              </div>

              <ui-select
                :value="inputs.type"
                :options.prop="map(PROMOTION_TYPES, type => ({label: sentenceCase(type), value: type }))"
                :disabled="!!promotionUuid"
                name="type"
                placeholder="Select the type"
                label="Type*"
                class="w-100 mb-4 d-block"
                data-test-id="type-select"
                @selectoption="({ detail }) => inputs.type = detail"
              />

              <div class="mb-4">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Amount in {{ currencySymbol }}*
                </label>
                <MuiInputText
                  v-model="inputs.amount"
                  v-validate="{
                    isRequired: true,
                    isPattern: PATTERN_INPUT_VALIDATIONS.decimalNumber,
                  }"
                  placeholder="Enter the amount"
                  name="amount"
                  data-test-id="amount-input"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="pl-2">
              <label class="d-block emobg-font-weight-semibold mb-1">
                Active
              </label>
              <ui-toggle
                :value="inputs.active"
                :text="inputs.active ? 'Active' : 'Inactive'"
                :disabled="!!isEndDateInPast"
                class="mb-4 py-1"
                data-test-id="active-toggle"
                @changevalue="({ detail }) => inputs.active = detail"
              />

              <ui-datetimepicker
                :size="SIZES.small"
                :date.prop="inputs.endDate"
                :disabled="!inputs.startDate"
                :range.prop="validEndDateRange"
                label="End date"
                name="end"
                data-test-id="end_date_input"
                class="my-4 d-block"
                skiptime
                @datechanged="({ detail }) => {
                  inputs.endDate = detail;
                  validateEndDate(detail);
                }"
              />

              <div
                v-if="!promotionUuid"
                class="mb-4"
              >
                <label class="d-block emobg-font-weight-semibold mb-1">
                  External name*
                </label>
                <MuiInputText
                  v-model="inputs.name"
                  v-validate="{
                    isRequired: true,
                  }"
                  placeholder="Enter the name"
                  name="name"
                  data-test-id="name-input"
                />
              </div>

              <div class="mb-4">
                <label class="d-block emobg-font-weight-semibold mb-1">
                  Duration (days)*
                </label>
                <MuiInputText
                  v-model="inputs.duration"
                  v-validate="{
                    isRequired: true,
                    isPattern: PATTERN_INPUT_VALIDATIONS.wholeNumber,
                  }"
                  placeholder="Enter the duration in days"
                  name="duration"
                  data-test-id="duration-input"
                />
              </div>
            </div>
          </div>
        </div>
      </MuiValidationWrapper>
    </template>
    <template slot="footer">
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <div class="d-flex justify-content-center">
          <CancelButton
            data-test-id="close_modal-button"
            @click="() => $emit('closeModal')"
          />
        </div>

        <div class="d-flex justify-content-center">
          <ui-button
            :disabled="!isFormValid || hasSameValues"
            :loading="newPromotionStatus.LOADING"
            class="wmin-initial"
            data-test-id="request-button"
            @clickbutton="request"
          >
            {{ promotionUuid ? 'Save' : 'Create' }}
          </ui-button>
        </div>
      </div>
    </template>
  </GenericModalComponent>
</template>
