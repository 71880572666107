var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "PromotionsListView",
      attrs: { "data-test-id": "promotions_list-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [
            _vm._v(" Credit and Promotions "),
          ]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isModalVisible = true
                },
              },
            },
            [_vm._v(" Add promotion ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "promotions",
            attrs: {
              facets: _vm.facets,
              filters: _vm.getOperatorFilter({ attribute: "cs_operator_fk" }),
              index: _vm.PROMOTIONS_INDEX,
              "table-config": _vm.mainConfig,
              "item-actions": _vm.itemActions,
              "data-test-id": "list",
            },
          }),
        ],
        1
      ),
      _vm.isModalVisible
        ? _c("PromotionForm", {
            attrs: {
              "promotion-uuid": _vm.promotionUuid,
              callback: _vm.onFormSuccess,
              "data-test-id": "form",
            },
            on: { closeModal: _vm.closeModals },
          })
        : _vm._e(),
      _vm.isDeleteModalVisible
        ? _c("DeletePromotionComponent", {
            attrs: {
              "promotion-uuid": _vm.promotionUuid,
              callback: _vm.onFormSuccess,
              "data-test-id": "delete-form",
            },
            on: { closeModal: _vm.closeModals },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }